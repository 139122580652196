import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Label1, Label2, Label3, Label4, Label5, Label6 } from '@redoute/labels';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "labels"
    }}>{`Labels`}</h1>
    <p>{`Three levels of Labels`}</p>
    <Playground __position={0} __code={'<Label1>Magna labore adipisicing</Label1>\n<Label2>Eiusmod elit tempor incididunt mollit quis nostrud.</Label2>\n<Label3>\n  Ipsum adipisicing consectetur eiusmod voluptate sunt qui labore\n</Label3>\n<Label4>\n  Laboris adipisicing esse pariatur cupidatat dolor laborum nostrud ipsum\n  tempor qui.\n</Label4>\n<Label5>\n  Consequat consequat do consectetur eiusmod proident Lorem exercitation non.\n</Label5>\n<Label6>\n  Ipsum adipisicing consectetur eiusmod voluptate sunt qui labore\n</Label6>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Label1,
      Label2,
      Label3,
      Label4,
      Label5,
      Label6,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Label1 mdxType="Label1">Magna labore adipisicing</Label1>
    <Label2 mdxType="Label2">Eiusmod elit tempor incididunt mollit quis nostrud.</Label2>
    <Label3 mdxType="Label3">Ipsum adipisicing consectetur eiusmod voluptate sunt qui labore</Label3>
    <Label4 mdxType="Label4">Laboris adipisicing esse pariatur cupidatat dolor laborum nostrud ipsum tempor qui.</Label4>
    <Label5 mdxType="Label5">Consequat consequat do consectetur eiusmod proident Lorem exercitation non.</Label5>
    <Label6 mdxType="Label6">Ipsum adipisicing consectetur eiusmod voluptate sunt qui labore</Label6>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      